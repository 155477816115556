import React, { useState, useCallback, useEffect, useContext, useRef } from "react";
import { TextField } from "office-ui-fabric-react/lib-commonjs/TextField";

import css from "./information-form.module.css";
import ReactUtils from "../../helpers/ReactUtils";
import { TranslationContext, ConfigurationContext } from "../../providers";
import CountrySelect, { DEFAULT_COUNTRY, ICountry } from "../CountrySelect";
import { Translation } from "../Translation";
import SelectionPayment from "../SelectionPayment";

export enum Type {
    Individual = "membership.header.individual",
    Company = "membership.header.company"
}

interface IInformationFormProps {
    types: Array<Type>;
    withSelectionPayment: boolean;
    withAccountAndTaxReceipt: boolean;
    onSubmit: (formdata: FormData, type: Type) => any;
}

export default function InformationForm(props: IInformationFormProps) {
    const translationConsumer = useContext(TranslationContext);
    const selectionPaymentContainer = useRef(null);
    const [countryCode, setCountryCode] = useState<string | null>(DEFAULT_COUNTRY);
    const [type, setType] = useState<Type>(props.types[0]);
    const [paymentType, setPaymentType] = useState<string | null>(null);
    const [showReferent, setShowReferent] = useState<boolean>(false);

    const getTranslatedType = useCallback(
        function(type: Type): string {
            return translationConsumer.get(type);
        }, []
    );

    const submitHandler = useCallback(
        async function(event: React.FormEvent<HTMLFormElement>): Promise<void> {
            event.preventDefault();

            const formData: FormData = new FormData(event.target as any);
            if (formData.get("recu_fiscal") == "on") {
                formData.set("recu_fiscal", "true");
            }
            formData.set("moyen_paiement", "CARTE");
            props.onSubmit(formData, type);
            // if ((props.withSelectionPayment && paymentType != null) || !props.withSelectionPayment) {
            //     formData.set("moyen_paiement", paymentType ?? "");

            //     props.onSubmit(formData, type);
            // } else {
            //     if (selectionPaymentContainer?.current) {
            //         (selectionPaymentContainer.current as unknown as HTMLElement).style.border = "2px dashed red";
            //         (selectionPaymentContainer.current as unknown as HTMLElement).style.paddingBottom = "5px";
            //     }
            // }
        }, [type, paymentType]
    );

    const tabClickHandler = useCallback(
        function(event: React.MouseEvent<HTMLLIElement, MouseEvent>): void {
            const currentElement: HTMLLIElement = event.target as HTMLLIElement;
            const tabId: string | undefined = currentElement.dataset.tab;

            setType(tabId as Type);
        }, []
    );

    const onChangeInputHandler = useCallback(
        function(event: React.ChangeEvent<HTMLInputElement>) {
            setShowReferent(event.target.checked);
        }, [type]
    );

    const onChangeCountryHandler = useCallback(
        function(country: ICountry): void {
            setCountryCode(country.code);
        }, []
    );

    const onChangeSelectionPaymentHandler = useCallback(
        function(paymentTypeConstant: string): void {
            setPaymentType(paymentTypeConstant);

            if (paymentTypeConstant != null && selectionPaymentContainer) {
                (selectionPaymentContainer.current as unknown as HTMLElement).style.border = "none";
                (selectionPaymentContainer.current as unknown as HTMLElement).style.paddingBottom = "0";
            }
        }, []
    );

    useEffect(
        function(): void {
            Array.from(
                document.querySelectorAll(`ul.${css.tabs} li`)
            ).forEach(li => li.classList.remove(`${css.current}`));

            Array.from(
                document.querySelectorAll(`.${css["tab-content"]}`)
            ).forEach(div => div.classList.remove(`${css.current}`));

            document.querySelector(`ul.${css.tabs} li[data-tab="${type}"]`)?.classList?.add(`${css.current}`);

            document.querySelector(`#${getTranslatedType(type)}`)?.classList?.add(`${css.current}`);
        }, [type]
    );

    return (
        <ConfigurationContext.Consumer>
            {
                configurationConsumer => (
                    <div className={ css.wrapper }>
                        <ul className={ css.tabs }>
                            {
                                props.types.length > 1 && props.types.map(customType => {
                                    return <li key={ customType } className={ (type === customType ? [css.current] : []).join(" ") } data-tab={ customType } onClick={ tabClickHandler }>{ getTranslatedType(customType) }</li>;
                                })
                            }
                        </ul>

                        <div id={ getTranslatedType(type) } className={ [css.login, css["tab-content"], css.current].join(" ") }>
                            <form onSubmit={ submitHandler }>
                                <div className={ css["form-section"] }>
                                    <p>
                                        <i>
                                            <Translation t={ translationConsumer.t } i18nKey="membership.fields.required" />
                                        </i>
                                    </p>
                                </div>
                                
                                <div className={ css["form-section"] }>
                                    {
                                        ReactUtils.either(
                                            type === Type.Individual,
                                            <TextField label={ translationConsumer.get("membership.fields.name")} name="nom_prenom" required={ true } type="text" />,
                                            <TextField label={ translationConsumer.get("membership.fields.socialReason")} name="raison_sociale" required={ true } type="text" />
                                        )
                                    }
                                </div>

                                <div className={ css["form-section"] }>
                                    <TextField label={ translationConsumer.get("membership.fields.address")} name="adresse" required={ true } type="text" />
                                </div>

                                <div className={ css["form-section"] }>
                                    <TextField label={ translationConsumer.get("membership.fields.postalCode")} name="code_postal" required={ true } type="text" />
                                </div>

                                <div className={ css["form-section"] }>
                                    <TextField label={ translationConsumer.get("membership.fields.city")} name="ville" required={ true } type="text" />
                                </div>

                                <div className={ css["form-section"] }>
                                    <label className={ css.label }>Pays</label>
                                    <CountrySelect name="pays" onChange={ onChangeCountryHandler } /> 
                                </div>

                                <div className={ css["form-section"] }>
                                    <TextField label={ translationConsumer.get("membership.fields.phone")} name="telephone" required={ true } type="tel" />
                                </div>

                                <div className={ css["form-section"] }>
                                    <TextField label={ translationConsumer.get("membership.fields.email")} name="email" required={ true } type="email" />
                                </div>

                                {
                                    ReactUtils.onlyIf(
                                        props.withAccountAndTaxReceipt,
                                        <React.Fragment>
                                            <div className={ css["form-section"] }>
                                                <p>
                                                    <Translation t={ translationConsumer.t } i18nKey="membership.informationDonation" />
                                                </p>

                                                <TextField label={ translationConsumer.get("membership.fields.amount")} name="montant" required={ true } type="number" step="1" min={ configurationConsumer.adhesion.montant_minimum } />
                                            </div>
                                        </React.Fragment>
                                    )
                                }

{
                                    ReactUtils.onlyIf(
                                        props.withAccountAndTaxReceipt && countryCode == DEFAULT_COUNTRY,
                                        <div className={ css["form-section"] } style={{ margin: "10px 0 20px" }}>
                                            {
                                                ReactUtils.onlyIf(
                                                    type === Type.Individual,
                                                    <p>{ translationConsumer.get("membership.informationTaxReceiptForIndividual") }</p>
                                                )
                                            }

                                            {
                                                ReactUtils.onlyIf(
                                                    type === Type.Company,
                                                    <p>{ translationConsumer.get("membership.informationTaxReceiptForCompany") }</p>
                                                )
                                            }
                                            
                                            <label htmlFor="recu_fiscal">{ translationConsumer.get("membership.fields.withTaxReceipt") } </label>
                                            <input type="checkbox" name="recu_fiscal" id="recu_fiscal" onChange={ onChangeInputHandler } checked={ showReferent } />
                                        </div>
                                    )
                                }

                                {
                                    ReactUtils.onlyIf(
                                        type === Type.Company && showReferent === true && countryCode == DEFAULT_COUNTRY,
                                        <React.Fragment>
                                            <div className={ css["form-section"] }>
                                                <TextField label={ translationConsumer.get("membership.fields.referentName")} name="nom_referent" required={ true } type="text" />
                                            </div>
                                            <div className={ css["form-section"] } style={{ marginBottom: "20px" }}>
                                                <TextField label={ translationConsumer.get("membership.fields.referentAddress")} name="adresse_referent" required={ true } type="text" />
                                            </div>
                                        </React.Fragment>
                                    )
                                }

                                {
                                    ReactUtils.onlyIf(
                                        props.withSelectionPayment,
                                        <div className={ css["form-section"] }>
                                            <div className={ css["selection-payment"] } ref={ selectionPaymentContainer }>
                                                <SelectionPayment onChange={ onChangeSelectionPaymentHandler } />
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    ReactUtils.onlyIf(
                                        configurationConsumer.isLoaded,
                                        <div className={ [css["form-section"], css["btn-container"]].join(" ") }>
                                            <input type="submit" value={ translationConsumer.get("membership.fields.submit") } />
                                        </div>
                                    )
                                }
                            </form>
                        </div>
                    </div>
                )
            }
        </ConfigurationContext.Consumer>
    );
}
