import React, { useCallback, useContext } from "react";
import { ChoiceGroup, IChoiceGroupOption } from "office-ui-fabric-react/lib-commonjs/ChoiceGroup";

import logoCB from "../../assets/logo-cb.jpg";
import logoPaypal from "../../assets/logo-paypal.png";
import { TranslationContext } from "../../providers";

interface ISelectionPaymentProps {
    onChange: (PBX_TYPEPAIEMENT: string) => void;
}

export default function SelectionPayment(props: ISelectionPaymentProps) {
    const translationConsumer = useContext(TranslationContext);

    const changeHandler = useCallback(
        function(_?: React.FormEvent<HTMLElement>, option?: IChoiceGroupOption): void {
            if (option) {
                props.onChange(option.key);
            }
        }, []
    );

    const options: Array<IChoiceGroupOption> = [
        // {
        //     key: "CARTE",
        //     imageSrc: logoCB,
        //     selectedImageSrc: logoCB,
        //     imageSize: { width: 70, height: 50 },
        //     text: translationConsumer.get("payment.paymentType.paymentCard")
        // },
        // {
        //     key: "PAYPAL",
        //     imageSrc: logoPaypal,
        //     selectedImageSrc: logoPaypal,
        //     imageSize: { width: 100, height: 50 },
        //     text: "Paypal",
        //     disabled: true
        // }
    ];

    return (
        <ChoiceGroup label={ "" } options={ options } onChange={ changeHandler } styles={{ flexContainer: { display: "block" } }} />
    );
}
